import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { MatFormFieldModule, MatError } from '@angular/material/form-field';
import { AuthguardService } from './../services/Authguard/authguard.service';
import { usersService } from '../services/tikaUser/user.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminLayoutComponent } from './shared/admin-layout/admin-layout.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthComponent } from './auth/auth/auth.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AddUserComponent } from './users/add-user/add-user.component';
import { MatSidenavModule, MatSidenav } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list'
import { MatIconModule } from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { RegisterComponent } from './auth/register/register.component';
import { DropdownComponent } from './shared/dropdown/dropdown.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MatTableModule} from '@angular/material/table';
import { SearchDirective } from './shared/search.directive';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MatSortModule } from '@angular/material/sort';
import {MatDatepickerModule } from '@angular/material/datepicker'
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { AddBusinessUserComponent } from './businessUser/add-business-user/add-business-user.component';
import { BusinessUserListComponent } from './businessUser/business-user-list/business-user-list.component';
import { AddCustomerComponent } from './customer/add-customer/add-customer.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { AddBreedComponent } from './breed/add-breed/add-breed.component';
import { BreedListComponent } from './breed/breed-list/breed-list.component';
import { AddVaccinesComponent } from './vaccines/add-vaccines/add-vaccines.component';
import { VaccineListComponent } from './vaccines/vaccine-list/vaccine-list.component';
import { AddDeliveryComponent } from './delivery/add-delivery/add-delivery.component';
import { DeliveryListComponent } from './delivery/delivery-list/delivery-list.component';
import { VaccineGroupListComponent } from './vaccineGroup/vaccine-group-list/vaccine-group-list.component';
import { AddVaccineGroupComponent } from './vaccineGroup/add-vaccine-group/add-vaccine-group.component';
import { NearbyVetListComponent } from './nearbyVet/nearby-vet-list/nearby-vet-list.component';
import { NearbyVetsListComponent } from './nearByVets/nearby-vets-list/nearby-vets-list.component';
import { NearbyAppointmentComponent } from './appointment/nearby-appointment/nearby-appointment.component';
import { OrdersComponent } from './order/orders/orders.component';
import { ListingComponent } from './listing/listing.component';
import { HelpComponent } from './help/help.component';
import { VetCustomersComponent } from './vet-customers/vet-customers.component';
import { RxListingComponent } from './RxManager/rx-listing/rx-listing.component';
import { RxViewComponent } from './RxManager/rx-view/rx-view.component';
import { AddVendorComponent } from './vendor/add-vendor/add-vendor.component';
import { ListVendorComponent } from './vendor/list-vendor/list-vendor.component';
import { ListConsultingComponent } from './consulting/list-consulting/list-consulting.component';
import { AddInventoryComponent } from './inventory/add-inventory/add-inventory.component';
import { ListInventoryComponent } from './inventory/list-inventory/list-inventory.component';
import { AddAccessoriesComponent } from './accessories/add-accessories/add-accessories.component';
import { ListAccessoriesComponent } from './accessories/list-accessories/list-accessories.component';
import { AddDeliveryGuyComponent } from './delivery-guy/add-delivery-guy/add-delivery-guy.component';
import { ListDeliveryGuyComponent } from './delivery-guy/list-delivery-guy/list-delivery-guy.component';
import { ListOrderComponent } from './vendor-order/list-order/list-order.component';
import { ListPaymentComponent } from './payment/list-payment/list-payment.component';
import { ListBreedComponent } from './cat-breed/list-breed/list-breed.component';
import { CatAddBreedComponent } from './cat-breed/cat-add-breed/cat-add-breed.component';
import { CatAddVaccineComponent } from './cat-vaccine/cat-add-vaccine/cat-add-vaccine.component';
import { CatListVaccineComponent } from './cat-vaccine/cat-list-vaccine/cat-list-vaccine.component';
import { AddLocationComponent } from './location/add-location/add-location.component';
import { ListLocationComponent } from './location/list-location/list-location.component';
import { AddClientComponent } from './client/add-client/add-client/add-client.component';
import { EditClientComponent } from './client/edit-client/edit-client/edit-client.component';
import { ListClientComponent } from './client/list-client/list-client/list-client.component';
import { StaffListComponent } from './client/staff-list/staff-list.component';
import { PatientListComponent } from './client/patient-list/patient-list.component';

@NgModule({
  declarations: [ AdminLayoutComponent,NearbyVetListComponent,
     UserListComponent,LoginComponent,
      HeaderComponent, FooterComponent, 
      LoginComponent, AuthComponent, 
      ForgotPasswordComponent,
       ResetPasswordComponent, 
       AddUserComponent,
        RegisterComponent, 
       DropdownComponent, 
       DashboardComponent, 
       SearchDirective, EditUserComponent, ProfileComponent, ChangePasswordComponent, AddBusinessUserComponent, BusinessUserListComponent, AddCustomerComponent, CustomerListComponent, AddBreedComponent, BreedListComponent, AddVaccinesComponent, VaccineListComponent,
      AddDeliveryComponent,
     
    DeliveryListComponent,
  VaccineGroupListComponent,
  NearbyVetsListComponent,
  NearbyAppointmentComponent,
  AddVaccineGroupComponent,
  OrdersComponent,
  ListingComponent,
  HelpComponent,
  VetCustomersComponent,
  RxListingComponent,
  RxViewComponent,
  AddVendorComponent,
  ListVendorComponent,
  ListConsultingComponent,
  AddInventoryComponent,
  ListInventoryComponent,
  AddAccessoriesComponent,
  ListAccessoriesComponent,
  AddDeliveryGuyComponent,
  ListDeliveryGuyComponent,
  ListOrderComponent,
  ListPaymentComponent,
  ListBreedComponent,
  CatAddBreedComponent,
  CatAddVaccineComponent,
  CatListVaccineComponent,
  AddLocationComponent,
  ListLocationComponent,
  AddClientComponent,
  EditClientComponent,
  ListClientComponent,
  StaffListComponent,
  PatientListComponent,
  
],
imports: [
  CommonModule,
  AdminRoutingModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  MatIconModule,
  MatDatepickerModule,
  MatCardModule,
  MatNativeDateModule,
  MatSortModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatTableModule,
  MatPaginatorModule,
  MatSelectModule,
  MatFormFieldModule,
  FormsModule,
  RxReactiveFormsModule,
  NgxIntlTelInputModule,
  ReactiveFormsModule,
  ],
  providers:[
    usersService,AuthguardService,
    MatSidenav,
    MatSidenavModule,
    MatDatepickerModule,
    MatTableModule,
    
  ],
  exports:[MatSortModule]

})
export class AdminModule { }
