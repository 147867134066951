// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyDghbp0khgqvvJJHHQsNjf1wirOXhPiGwQ",
    authDomain: "dogsandjoys-37499.firebaseapp.com",
    projectId: "dogsandjoys-37499",
    storageBucket: "dogsandjoys-37499.appspot.com",
    messagingSenderId: "576016011046",
    appId: "1:576016011046:web:f40f15f761acd2cfa0fbe7",
    measurementId: "G-68D47GCD5S"
  },
  api:{
    customer : "customer",
    admin : "admin",
    businessUser : "businessUser",
    address : "address",
    breed : "breed",
    vaccineSuggestion : "vaccineSuggestion",
    vaccineGroup : "vaccineGroup",
    delivery : "delivery",
    nearbyVet : "nearbyVet",
    appoinment : "appoinment",
    order : "order",
    count:"count",
    dog:"dog",
    food:"food",
    request:"request",
    help:"help",
    vetPrescribe : "vetPrescribe",
    remoteConsulting : "remoteConsulting",
    accessories:"accessories",
    payments:"payments",
    catBreed:"catBreed",
    catVaccine:"catVaccine",
    location:"location",
    clients:'clients'

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
