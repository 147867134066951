<div class="container">
    <div class="row d-flex justify-content-center">
        <div class="col-12">
            <mat-card>
                <form #f="ngForm">
                    <div class="row ">
                        <div class="col-12 ">
                            <h2 class="heading" *ngIf="!id">Add Client</h2>
                            <h2 class="heading" *ngIf="id">Edit Client</h2>

                        </div>
                        <div class="inputarea">
                            <div class="col-12">
                                <input class="inputbox" name="addForm.name" [(ngModel)]="addForm.name" value="" #addForm.name="ngModel" placeholder="Name" [ngClass]="{ 'is-invalid': f.submitted && addForm.name.invalid }" required>
                                <span class="material-icons">
                                    face

                                </span>
                                <!-- <div [hidden]="addForm.name.valid || addForm.name.pristine" class="erro1">Name is Required
                                </div> -->

                            </div>



                            <div class="col-12">
                                <!-- 
                                <mat-select [(ngModel)]="addForm.type" name="type" placeholder="Type">
                                    <mat-option value="Homeopathy">
                                        Homeopathy
                                    </mat-option>
                                    <mat-option value="Alopathy">
                                        Alopathy
                                    </mat-option>
                                </mat-select> -->

                                <select class="inputbox" name="type" [(ngModel)]="addForm.type" [value]="">
                                    <option [value]="'Homeopathy'" [selected]="addForm.type ==='Homeopathy'">Homeopathy</option>
                                    <option [value]="'Allopathy'" [selected]="addForm.type === 'Allopathy'">Allopathy</option>
                                  </select>

                            </div>

                            <div class="col-12">
                                <input class="inputbox" name="addForm.siteTitle" [(ngModel)]="addForm.siteTitle" value="" #addForm.name="ngModel" placeholder="Clinic Title" [ngClass]="{ 'is-invalid': f.submitted && addForm.siteTitle.invalid }" required>

                                <!-- <div [hidden]="addForm.siteTitle.valid || addForm.siteTitle.pristine" class="erro1">Title is Required
                                </div> -->

                            </div>

                            <div class="col-12">
                                <input type="number" class="inputbox" name="addForm.referalCode" [(ngModel)]="addForm.referalCode" value="" #addForm.name="ngModel" placeholder="Referal Code" [ngClass]="{ 'is-invalid': f.submitted && addForm.referalCode.invalid }" required>

                                <!-- <div [hidden]="addForm.siteTitle.valid || addForm.siteTitle.pristine" class="erro1">Title is Required
                                </div> -->

                            </div>
                            <div class="col-12">
                                Favicon

                                <input #fileInput id="fileInput" type="file" (change)="onFileInputclinic($event)" />
                            </div>


                            <div class="col-12">

                                <div class="row">
                                    <div class="col-10">
                                        <input class="inputbox" placeholder="Branch" name="branch" [(ngModel)]="newDetails.branch" #branch="ngModel">

                                    </div>

                                    <div class="col-2">
                                        <span (click)="addbranch()" class="material-icons addClass">
                                          add_circle_outline
                                          </span>

                                    </div>
                                </div>
                                <div *ngFor="let other of addForm.branch;let i = index" class="row">
                                    <div class="col-10">
                                        <label class="AllLabelContainer"> <h2 class="AllLabels">Branch</h2>
                                         <p *ngIf="!other.edit"> {{other.branch}}</p>
                                        <input *ngIf="other.edit" class="inputbox" placeholder="branch" name="branch{{i}}"  [(ngModel)]="other.branch" #branch{{i}}="ngModel" >
                                      </label>
                                    </div>

                                    <div class="col-2">
                                        <label class="AllLabelContainer"> <h2 class="AllLabels">Actions</h2>
            
                                      <span *ngIf="!other.edit" (click)="other.edit = true" class="material-icons editClass ">
                                        edit
                                        </span>
                                        <span *ngIf="!other.edit" (click)="deletebranch(i)" class="material-icons deleteClass ">
                                          delete
                                          </span>
                                          <span *ngIf="other.edit" (click)="other.edit = false" class="material-icons  doneClass ">
                                              check_circle_outline
                                            </span>
                                            </label>
                                    </div>
                                </div>
                            </div>
                            <!-- <p>Please provide Root Manager.</p> -->

                            <div class="col-12">
                                <input class="inputbox" name="name" [(ngModel)]="addForm.manager.name" #name="ngModel" placeholder="Manager Name" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }">

                                <!-- <div [hidden]="name.valid || name.pristine" class="erro1">Manager Name is Required
                                </div> -->


                            </div>
                            <div class="col-12">
                                <input class="inputbox" placeholder="Manager Mobile" name="mobile" [(ngModel)]="addForm.manager.mobile" #mobile="ngModel" [ngClass]="{ 'is-invalid': f.submitted && mobile.invalid }" required readonly disabled>


                            </div>

                            <div class="col-12">
                                <label>Activate Clinic</label>
                                <input class="inputbox width-set" type="checkbox" name="isActive" [(ngModel)]="addForm.isActive" #isActive="ngModel" [ngClass]="{ 'is-invalid': f.submitted && isActive.invalid }">


                            </div>

                            <div class="col-12">
                                <input class="inputbox" placeholder="Manager Email" name="email" [(ngModel)]="addForm.manager.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && addForm.manager.email.invalid }" email>
                                <span class="material-icons">
                                    email
                                </span>
                                <div [hidden]="email.valid || email.pristine" class="erro1">
                                    <div *ngIf="email.errors?.required">Email is required</div>
                                    <div *ngIf="email.errors?.email">
                                        Email must be a valid email address
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">



                                <div class="col-12">
                                </div>

                                <button mat-button  class="submit" (click)="EditClient()">submit</button>

                            </div>

                        </div>

                    </div>
                </form>
            </mat-card>
        </div>
    </div>

</div>