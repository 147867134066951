import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from 'src/app/services/crudService/crud-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { ClientService } from 'src/app/services/crudService/client.service';
import { FileUpload } from 'src/app/shared/classes/file-upload';
import { FileUploadService } from '../../../../services/fileUpload/file-upload.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  addForm: any;
  id: any;
  Visibility: any = 'visibility_off'
  type: string = 'password'; icon: string = "check_circle_outline";
  icon1 = "check_circle";
  adoptionStatus: boolean = true;
  newDetails: any = {
    "branch": "",

  }
  selectedFiles:any
    currentUpload: FileUpload;
  image:any

  constructor(private crudService: CrudServiceService,
    private activatedRoute: ActivatedRoute,
    private ClientService: ClientService,
    private toast: ToastComponent,
    private FileUploadService:FileUploadService,

    private router: Router) {


    this.addForm = {
      "name": "",
      "siteTitle":"",
      "referalCode":"",
      "image":"",
      "type":"",
      manager: {
        "name": "",
        "email": "",
        "mobile": "",
        "role": "63e1fce02c789626ac4239ce",
        "approval": "Approved"
      },
      branch: [],
    }


    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    if (this.id) {
      this.crudService.getDedtail(this.id, environment.api.customer).then((res: any) => {
        this.addForm = res;

      })
    }

  }

  changeVisibility() {
    if (this.Visibility == "visibility_off") {
      this.Visibility = "visibility"
      this.type = 'text'
    }
    else if (this.Visibility == "visibility") {
      this.Visibility = 'visibility_off'
      this.type = 'password';
    }
  }
  addbranch() {
    this.addForm.branch.push(JSON.parse(JSON.stringify(this.newDetails)));
    this.newDetails = {
      "branch": "",

    }
  }
  deletebranch(index) {
    this.addForm.branch.splice(index, 1);
  }
  AddClient() {
    console.log(this.addForm, 'this.addForm')
    this.ClientService.addclient(this.addForm, environment.api.clients).then((res: any) => {
      if (res?.message == 'User with this mobile No. or Email Id already exist') {
        console.log('yes')
        this.toast.toastError(res?.message)

      } else {
        this.toast.toastSuccess("Client Created successfully.");
        this.router.navigate(['/admin/client/list']);
      }
    })

  }

  ngOnInit(): void {
  }
  onFileInputclinic (event:any) {
    this.selectedFiles = event.target.files;
    let file = this.selectedFiles.item(0);
    this.currentUpload = new FileUpload(file);
    this.uploadImageclinic()
    console.log(file,'file')
    }
  
  
    uploadImageclinic(){
      this.FileUploadService.pushUpload(this.currentUpload,'/AdminClinicImage/').then((res:any)=>{
        console.log(res);
        this.addForm.image=res?.url;
        console.log(this.image);
      });
    }
  checkAvail() {
    setTimeout(() => {
      if (this.adoptionStatus) {
        this.addForm.adoptionStatus = "Adoption"
      } else {
        this.addForm.adoptionStatus = "Not Adoption"

      }
    }, 10);

  }
}

